import moment from 'moment'

export default {
  computed: {
    taskHeaderData () {
      let extData = [
        [
          [this.task.author ? (this.task.author.fullname ? this.task.author.fullname : (this.task.author.label ? this.task.author.label : 'b.d.')) : 'b.d.', 'pages.taskDetails.header.author', `${this.task.id}-header-author`],
          [this.task.assignee ? (this.task.assignee.fullname ? this.task.assignee.fullname : (this.task.assignee.label ? this.task.assignee.label : 'b.d.')) : 'b.d.', 'pages.taskDetails.header.assignee', `${this.task.id}-header-assignee`],
          [this.task.state ? this.task.state.label : 'b.d.', 'pages.taskDetails.header.status', `${this.task.id}-header-state`]
        ]
      ]
      let defaultData = [
        [
          [this.task.additionalData.firstChangeStateDate ? this.formatDate(this.task.additionalData.firstChangeStateDate, 'YYYY-MM-DD') : 'b.d', 'Zlecone', `${this.task.id}-header-firstChangeStateDate`],
          [this.task.state ? this.task.state.label : 'b.d.', 'Status', `${this.task.id}-header-state`],
          [this.task.author ? (this.task.author.fullname ? this.task.author.fullname : (this.task.author.label ? this.task.author.label : 'b.d.')) : 'b.d.', 'Autor', `${this.task.id}-header-author`],
          [this.task.assignee ? (this.task.assignee.fullname ? this.task.assignee.fullname : (this.task.assignee.label ? this.task.assignee.label : 'b.d.')) : 'b.d.', 'Opiekun', `${this.task.id}-header-assignee`]
        ],
        [
          [this.getType(this.task.supportedClass, 'task') || 'b.d.', 'Rodzaj', `${this.task.id}-header-supportedClass-task`],
          [this.task.additionalData && this.task.additionalData.orderType || 'b.d', 'Zlecenie', `${this.task.id}-header-orderType`],
          [this.getType(this.task.supportedClass, 'subject') || 'b.d.', 'Przedmiot', `${this.task.id}-header-supportedClass-subject`],
          [this.task.closedAt ? moment(this.task.closedAt).format('YYYY-MM-DD') : 'b.d.', 'Zamknięto', `${this.task.id}-header-closedAt`]
        ]
      ]
      let extuniData = [
        [
          [this.task.additionalData.firstChangeStateDate ? this.formatDate(this.task.additionalData.firstChangeStateDate, 'YYYY-MM-DD') : 'b.d', 'Zlecone', `${this.task.id}-header-firstChangeStateDate`],
          [this.task.state ? this.task.state.label : 'b.d.', 'Status', `${this.task.id}-header-state`],
          [this.task.author ? (this.task.author.fullname ? this.task.author.fullname : (this.task.author.label ? this.task.author.label : 'b.d.')) : 'b.d.', 'Autor', `${this.task.id}-header-author`],
          [this.task.assignee ? (this.task.assignee.fullname ? this.task.assignee.fullname : (this.task.assignee.label ? this.task.assignee.label : 'b.d.')) : 'b.d.', 'Opiekun', `${this.task.id}-header-assignee`]
        ]
      ]
      let intbwnData = [
        [
          [this.task.additionalData.firstChangeStateDate ? this.formatDate(this.task.additionalData.firstChangeStateDate, 'YYYY-MM-DD') : 'b.d', 'Zlecone', `${this.task.id}-header-firstChangeStateDate`],
          [this.task.state ? this.task.state.label : 'b.d.', 'Status', `${this.task.id}-header-state`],
          [this.task.author ? (this.task.author.fullname ? this.task.author.fullname : (this.task.author.label ? this.task.author.label : 'b.d.')) : 'b.d.', 'Autor', `${this.task.id}-header-author`],
          [this.task.assignee ? (this.task.assignee.fullname ? this.task.assignee.fullname : (this.task.assignee.label ? this.task.assignee.label : 'b.d.')) : 'b.d.', 'Opiekun', `${this.task.id}-header-assignee`]
        ]
      ]

      let data = {
        extgsl: extData,
        extemn: extData,
        extacn: extData,
        extgcz: extData,
        extshp: extData,
        extgen: extData,
        extavi: extData,
        extpko: extData,
        demofokus: extData,
        extuni: extuniData,
        intgen: defaultData,
        intbls: defaultData,
        intbwn: intbwnData,
        intvhs: defaultData
      }
      // TODO Get task header data from backend
      return this.task ? (data.hasOwnProperty(this.service) ? data[this.service] : data['default']) : []
    }
  }
}
