<template>
  <base-form-modal
    :show="show"
    :title="title"
    :ok-text="okText"
    :cancel-text="cancelText"
    :btn-disabled="btnDisabled"
    @show="open"
    @close="$emit('close')"
    @beforeSubmit="bool => beforeSubmitTrigger = bool"
  >
    <form-modal 
      ref="form" 
      v-model="model" 
      :totalItems="totalItems"
      :is-before-submit="beforeSubmitTrigger"  />
  </base-form-modal>
</template>

<script>
import BaseFormModal from '../../../../share/modal/BaseFormModal.vue'
import FormModalMixin from '../../../../share/modal/FormModalMixin.js'
import FormModal from './ConfirmDeleteInvoiceForm.vue'

export default {
  components: {
    FormModal,
    BaseFormModal
  },
  mixins: [
    FormModalMixin,
  ],
  props: {
    totalItems: { type: Number, required: false }
  },
  data () {
    return {
      title: 'Potwierdź usunięcie faktury',
      okText: 'Tak',
      cancelText: 'Anuluj',
      btnDisabled: this.totalItems
    }
  },
  mounted () {
  },
  methods: {
    submitForm () {
      this.$emit('confirm')
    },
  }
}
</script>