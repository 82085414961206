<template>
  <div>
    <base-form-modal
      :show="show"
      :title="title"
      :ok-text="okText"
      :cancel-text="cancelText"
      :remember-model-after-close="true"
      @show="open"
      @close="$emit('close')"
      @beforeSubmit="bool => beforeSubmitTrigger = bool"
    >
      <form-modal
        ref="form"
        v-model="model"
        :is-before-submit="beforeSubmitTrigger"
        :task-id="taskId"
        :workflow-name="workflowName"
        :service="service"
        @multiselectInput="handleChangeUser"
      />
    </base-form-modal>
    <blocking-loader :show="loading">
      {{ $t(loaderMessage) }}
    </blocking-loader>
  </div>
</template>

<script>
import BaseFormModal from '../../../../share/modal/BaseFormModal'
import BlockingLoader from '../../../../share/BlockingLoader'
import FormModalMixin from '../../../../share/modal/FormModalMixin'
import FormModal from './form/ChangeState'
import api from '../../../../../api'
import ErrorNotify from '../../../../share/mixins/ErrorNotify'
import { mapActions } from 'vuex'
import WebStorage from '../../../../share/WebStorage'

export default {
  components: {
    BlockingLoader,
    FormModal,
    BaseFormModal
  },
  mixins: [
    FormModalMixin,
    ErrorNotify
  ],
  props: {
    isFormValid: { type: Boolean },
    changeStateEvent: { type: String, required: true },
    simpleTransition: { type: Boolean, required: true },
    taskId: { required: true },
    taskState: { required: true, default: '' },
    workflowName: { type: String, required: true }
  },
  data () {
    return {
      assigneesLoadedEvent: 'changeState:assignees:loaded',
      assigneesNotLoadedEvent: 'changeState:assignees:notLoaded',
      title: 'modals.changeState.moveForward',
      okText: 'modals.changeState.changeState',
      loading: false,
      loaderMessage: 'modals.changeState.loaderMessage',
      saveAllowed: false
    }
  },
  watch: {
    taskState (newVal) {
      this.$refs.form.taskState = newVal
    },
    simpleTransition (newVal) {
      if (newVal === true) {
        this.$events.on(this.changeStateEvent, this.submitForm)
      }
    }
  },
  mounted () {
    this.$refs.form.taskState = this.taskState
    this.$events.on(this.assigneesLoadedEvent, this.allowSave)
    this.$events.on(this.assigneesNotLoadedEvent, this.preventSave)
  },
  methods: {
    ...mapActions({
      getIncompleteTasks: 'getIncompleteTasks'
    }),
    allowSave () {
      this.saveAllowed = true
    },
    preventSave () {
      this.saveAllowed = false
    },
    callIncompleteTasksMethod () {
      let contractors = {}
      Object.entries(WebStorage.getSecurityActions()).filter(([contractorKey, contractorValue], index) => contractorValue.length !== 0 && (contractorKey === 'intgen' || contractorKey === 'intbls' )).forEach(([contractorKey, contractorValue], index) => contractors[contractorKey] = contractorValue)
      this.getIncompleteTasks({services: contractors})
    },
    handleChangeUser (event) {
      this.model.selectedUserUuid = event
    },
    getNextStateMethod () {
      return this.$isWithClients(this.service)
        ? api.request(this.service, 'put', `/tasks/${this.taskId}`, this.createDataToSend())
        : api.request(this.service, 'put', `/tasks/${this.workflowName}/${this.taskId}/next-state`, this.createDataToSend())
    },
    nextState () {
      this.getNextStateMethod()
        .then((response) => {
          if (response.data.hasOwnProperty('message') && response.data.message.includes('error:')) {
            this.$notify({
              type: 'info',
              text: response.data.message.split('error:')[1]
            })
            this.toggleLoader()
            return
          }
          this.$notify({
            type: 'success',
            text: this.$t('modals.changeState.notification.success')
          })

          this.$emit('close')
          this.$events.emit('task:changedState')
          this.toggleLoader()
        this.callIncompleteTasksMethod()
        })
        .catch((error) => {
          this.toggleLoader()
          this.toggleLoading()
        console.log(error)
          if (error.response.data.message.includes('oznaczenie uwag')) {
            this.$notify({
              type: 'info',
              text: error.response.data.message
            })
            return
          } else if (error.response.data.message.includes('#000013:')){
            this.$notify({
              type: 'error',
              text: error.response.data.message.split('#000013:')[1].split('.').join(' ')
            })
            return
          }
          this.errorNotify(error, error.response.data.message)
        })
    },
    submitForm () {
      if (!this.isFormValid) {
        return
      }
      if (this.saveAllowed) {
        this.toggleLoader()
        this.nextState()
      }
    },
    createDataToSend () {
      let data = {
        transitionName: this.model.selectedTransitionName,
        userUuid: this.model.selectedUserUuid ? this.model.selectedUserUuid : null
      }
      if (this.$isWithClients(this.service)) {
        data['nextState'] = true
        data['client'] = this.$route.meta.client
        if (this.workflowName.includes('appraisal')) {
          data['appraisal'] = true
        } else {
          data['main'] = true
        }
      }
      return data
    },
    toggleLoader () {
      this.loading = !this.loading
    }
  }
}
</script>
