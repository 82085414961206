<template>
  <div class="modal-text">
    {{this.displayText}}
  </div>
</template>
<script>
import FormModalValidation from '@/components/share/form/FormModalValidation.js'

export default {
  mixins: [
    FormModalValidation
  ],
  props: {
    value: { type: Object, required: true },
    totalItems: { type: Number, required: false },
  },
  computed: {
    displayText () {
      if (this.totalItems === 0) {
        return 'Nie zaznaczono faktur do usunięcia' 
      } 
      return `Czy na pewno chcesz usunąć zaznaczone faktury?`
    }
  }
}
</script>
<style scoped>
.modal-text {
  margin: 0 15px;
}
</style>