export default {
  methods: {
    getSuffix (text, suffixes) {
      let plur = ['ć', 'm', 'e', 'a', 'i', 't', 'o', 'c', 'y', 'n']
      if (text === 'jeden') {
        return suffixes[0]
      }
      if ((text[text.length - 1] === 'a' && text[text.length - 2] === 'w') || (text[text.length - 1] === 'y' && (text[text.length - 2] === 'z' || text[text.length - 2] === 'r'))) {
        return suffixes[1]
      } else if (plur.includes(text[text.length - 1])) {
        return suffixes[2]
      }
    },
    words (digit) {
      var single = ['', ' jeden', ' dwa', ' trzy', ' cztery', ' pięć', ' sześć', ' siedem', ' osiem', ' dziewięć']
      var teen = ['', ' jedenaście', ' dwanaście', ' trzynaście', ' czternaście', ' piętnaście', ' szesnaście', ' siedemnaście', ' osiemnaście', ' dziewietnaście']
      var tens = ['', ' dziesięć', ' dwadzieścia', ' trzydzieści', ' czterdzieści', ' pięćdziesiąt', ' sześćdziesiąt', ' siedemdziesiąt', ' osiemdziesiąt', ' dziewięćdziesiąt']
      var hundreds = ['', ' sto', ' dwieście', ' trzysta', ' czterysta', ' pięćset', ' sześćset', ' siedemset', ' osiemset', ' dziewięćset']
      var groups = [
        ['', '', ''],
        [' tysiąc', ' tysiące', ' tysięcy'],
        [' milion', ' miliony', ' milionów'],
        [' miliard', ' miliardy', ' miliardów'],
        [' bilion', ' biliony', ' bilionów'],
        [' biliard', ' biliardy', ' biliardów'],
        [' trylion', ' tryliony', ' trylionów']]

      if (!isNaN(digit)) {
        var result = ''
        var sign = ''
        if (digit === 0 || digit === '00' || digit === '0') {
          result = 'zero'
        }
        if (digit < 0) {
          sign = 'minus'
        }

        var g = 0
        while (digit > 0) {
          var s = Math.floor((digit % 1000) / 100)
          var n = 0
          var d = Math.floor((digit % 100) / 10)
          var j = Math.floor(digit % 10)
          if (d === 1 && j > 0) {
            n = j
            d = 0
            j = 0
          }
          var k = 2
          if (j === 1 && s + d + n === 0) {
            k = 0
          }
          if (j === 2 || j === 3 || j === 4) {
            k = 1
          }
          if (s + d + n + j > 0) {
            result = hundreds[s] + tens[d] + teen[n] + single[j] + groups[g][k] + result
          }

          g++
          digit = Math.floor(digit / 1000)
        }
        return sign + result
      }
      return false
    }
  }
}
