<template>
  <div class="content row">
    <template
      v-for="(document, index) in availableDocumentsFiltered"
    >
      <div
        v-if="availableDocumentsFiltered.length !== 0"
        :key="index"
        class="col-xs-6"
      >
        <button
          v-if="!document.name.includes('Wyp')"
          :key="document.value"
          class="zord-btn btn zord-btn-primary btn-primary button-generate-document center-block"
          @click="getProperTemplate(document)"
        >
          {{ document.name }}
        </button>
        <div
          v-if="document.name.includes('Wyp')"
          style="width: 88%; padding: 2%;"
          class="document-payment-select"
        >
          <select-type
            :options="payments"
            :label="'Formularz wypłaty'"
            :name="'wyplata'"
            :disabled="payments.length === 0"
            @input="getPaymentData"
          />
        </div>
      </div>
    </template>

    <div v-if="availableDocumentsFiltered.length === 0">
      <p
        class="content-empty"
        style="text-align: center"
      >
        Nie ma dokumentów do wygenerowania
      </p>
    </div>
  </div>
</template>

<script>
import FormModalValidation from '../../../../share/form/FormModalValidation'
import api from '../../../../../api/index'
import WebStorage from '../../../../share/WebStorage'
import Loader from '../../../../share/Loader'
import moment from 'moment'
import ErrorNotify from '../../../../share/mixins/ErrorNotify'
import SelectType from '../../../../share/form/type/SelectType'
import Words from './Words'

export default {
  components: {
    SelectType
  },
  mixins: [
    Words,
    FormModalValidation,
    Loader,
    ErrorNotify
  ],
  props: {
    value: {},
    availableDocuments: { required: true },
    task: { required: true },
    taskState: { required: true },
    taskId: { required: true }
  },
  data () {
    return {
      service: this.$route.meta.acl.service,
      subjectData: {},
      paymentsSelectVisible: false,
      payments: [],
      chosenPayment: null,
      paymentModeMap: {
        1: 'Konto dealera',
        2: 'Inne konto',
        3: 'Poszkodowany'
      },
      fieldsNames: {
        currentDate: 'Aktualna data',
        policyNumber: 'Numer polisy',
        policyType: 'Typ polisy',
        icClaimNumber: 'Nr szkody klienta',
        taskNumber: 'Numer zlecenia',
        accidentHappendAt: 'Data i godzina zdarzenia',
        applicatedAt: 'Data zgłoszenia szkody do klienta',
        accidentPlace: 'Miejsce zdarzenia',
        make: 'Marka pojazdu',
        model: 'Model pojazdu',
        registrationNumber: 'Numer rejestracyjny',
        ic: 'Ubezpieczyciel',
        victimFullname: 'Poszkodowany - Imię i nazwisko',
        victimStreet: 'Poszkodowany - adres',
        victimPostcode: 'Poszkodowany - kod pocztowy',
        victimCity: 'Poszkodowany - miasto',
        participantFullname: 'Uczestnik w szkodzie - Imię i nazwisko',
        participantStreet: 'Uczestnik w szkodzie - adres',
        participantPostcode: 'Uczestnik w szkodzie - kod pocztowy',
        participantCity: 'Uczestnik w szkodzie - miasto',
        participantRegistrationNumber: 'Uczestnik w szkodzie - numer rejestracyjny',
        participantMake: 'Uczestnik w szkodzie - marka pojazdu',
        participantModel: 'Uczestnik w szkodzie - model pojazdu',
        bankFullname: 'Bank - nazwa',
        bankStreet: 'Bank - adres',
        bankPostcode: 'Bank - kod pocztowy',
        bankCity: 'Bank - miasto',
        policeFullname: 'Jednostka policji - nazwa',
        policeStreet: 'Jednostka policji - adres',
        policePostcode: 'Jednostka policji - kod pocztowy',
        policeCity: 'Jednostka policji - miasto',
        claimPersonFullname: 'Osoba odpowiedzialna - Imię i nazwisko',
        claimPersonEmail: 'Osoba odpowiedzialna - email',
        comments: 'Informacje dodatkowe',
        // Wypłaty:
        // kosztySuma (Kwota wypłaty)
        costsSum: 'Kwota wypłaty',
        // potrącenia opis
        deductionsLabel: 'Potrącenia - opis',
        // potrącenia
        deductions: 'Potrącenia',
        // Odszkodowanie kwota
        payout: 'Odszkodowanie kwota',
        // Odszkodowanie kwota słownie
        payoutByWords: 'Odszkodowanie kwota słownie',
        // uzasadnienie Treść
        grounds: 'Uzasadnienie treść',
        // odbiorca (konto dealera , inne konto, poszkodowany)
        paymentRecipient: 'Odbiorca',
        recipientFullname: 'odbiorca - Nazwa',
        recipientStreet: 'odbiorca - adres',
        recipientCity: 'Odbiorca - miasto',
        recipientPostcode: 'Odbiorca - kod pocztowy'
      }
    }
  },
  computed: {
    availableDocumentsFiltered () {
      if (this.taskData.policyType === null) {
        return this.availableDocuments.filter(element => element.type === 'partner')
      }
      return this.availableDocuments.filter(element => element.type === this.policyTypeLabels.name || element.type === 'partner')
    },
    policyTypeLabels () {
      if (this.$store.state.base.currentTaskModel.hasOwnProperty('policyType')) {
        return this.$store.getters.getProperFieldSelectData('basic_info', 'policyType')
      } else {
        return undefined
      }
    },
    claimPersonLabels () {
      if (this.$store.state.base.currentTaskModel.hasOwnProperty('claimPerson')) {
        return this.$store.getters.getProperFieldSelectData('basic_info', 'claimPerson')
      } else {
        return undefined
      }
    },
    taskData () {
      return this.$store.getters.getCurrentSchemaModelReference
    }
  },
  mounted () {
    this.payments = this.getPaymentsSelectOptions()
  },
  methods: {
    getTemplateWithBankOrPolice (subject, document) {
      api.request('core', 'get', `/${subject}?format=multiselect&terms=${this.getSubjectFieldValue(subject)}`)
        .then((response) => {
          api.request('core', 'get', `/${subject}/${response.data[0].id}`)
            .then((response) => {
              this.subjectData = response.data[`${subject}`]
              this.getDefaultDocumentTemplate(document, this.parametersForDocument())
            })
        }).catch(() => {
          this.subjectData = {
            street: 'brak danych',
            postcode: 'brak danych',
            city: 'brak danych'
          }
          this.getDefaultDocumentTemplate(document, this.parametersForDocument())
        })
    },
    getDefaultDocumentTemplate (document, params) {
      api.request(this.service, 'post', `/documents-templates/${(params !== {} && `${document.id}`)}/search`, this.parametersForDocument())
        .then((response) => {
          this.toggleLoading()
          let data = response.data
          this.$store.commit('SET_DOCUMENT_GENERATOR_DATA', {
            documentId: document.id,
            name: document.name,
            documentContent: data,
            task: this.task,
            taskType: this.taskType,
            taskId: this.taskId,
            taskState: this.taskState,
            service: this.service,
            taskData: this.taskData,
            fieldsNames: this.fieldsNames,
            parametersForDocument: this.parametersForDocument()
          })
          WebStorage.setDocumentData(this.$store.state.base.documentData)
          delete response.data
          this.dataLoaded = true
          this.$store.commit('SET_CURRENT_TASK_SCHEMA', {})
          this.$store.commit('SET_CURRENT_TASK_MODEL', {})
          this.$router.push({ name: `${this.service}_${this.$route.meta.workflowName}_document_generator` })
        })
        .catch(() => {
          this.toggleLoading()
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować danych'
          })
        })
    },
    getProperTemplate (document) {
      if (document.namespace.includes('bank')) {
        this.getTemplateWithBankOrPolice('bank', document)
      } else if (document.namespace.includes('polic')) {
        this.getTemplateWithBankOrPolice('police', document)
      } else {
        this.getDefaultDocumentTemplate(document, this.parametersForDocument())
      }
    },
    getPaymentData (paymentId) {
      api.request(this.service, 'get', `/tasks/${this.task.id}/main/payment/${paymentId}`)
        .then((response) => {
          let document = this.availableDocuments.filter(element => element.namespace.includes('Wyplata'))[0]
          this.chosenPayment = response.data
          this.getDefaultDocumentTemplate(document, this.parametersForDocument())
        })
    },
    getPaymentsSelectOptions () {
      return this.task.additionalData.hasOwnProperty('payments') && this.task.additionalData.payments.length !== 0 && this.task.additionalData.payments.map(payment => {
        return {
          value: payment.id,
          label: `Wypłata numer: ${payment.id}`
        }
      }) || []
    },
    getSubjectFieldValue (subject) {
      return subject === 'bank' ? this.taskData.bankAssignment : this.taskData.policeDepartment
    },
    getVictimFullname () {
      return this.taskData.victims[0] &&
          this.taskData.victims[0].person &&
          this.taskData.victims[0].person.company ? this.taskData.victims[0] && this.taskData.victims[0].person && `${this.taskData.victims[0].person.name}` || 'brak danych'
        : this.taskData.victims[0] && this.taskData.victims[0].person && `${this.taskData.victims[0].person.forename} ${this.taskData.victims[0].person.surname}` ||
          'brak danych'
    },
    getParticipantFullname () {
      return this.taskData.accidentParticipants[0] &&
        this.taskData.accidentParticipants[0].person &&
        this.taskData.accidentParticipants[0].person.company ? this.taskData.accidentParticipants[0] && this.taskData.accidentParticipants[0].person && `${this.taskData.accidentParticipants[0].person.name}` || 'brak danych'
        : this.taskData.accidentParticipants[0] && this.taskData.accidentParticipants[0].person && `${this.taskData.accidentParticipants[0].person.forename} ${this.taskData.accidentParticipants[0].person.surname}` ||
          'brak danych'
    },
    parametersForDocument () {
      return {
        currentDate: moment().format('DD-MM-YYYY'),
        policyNumber: this.taskData.policyNumber || 'brak danych',
        policyType: this.policyTypeLabels && this.policyTypeLabels.description || 'brak danych',
        icClaimNumber: this.taskData.icClaimNumber || 'brak danych',
        taskNumber: this.task.number || 'brak danych',
        accidentHappendAt: this.taskData.accidentHappendAt || 'brak danych',
        applicatedAt: this.taskData.applicatedAt || 'brak danych',
        accidentPlace: this.taskData.accidentPlace || 'brak danych',
        make: (typeof this.taskData.make === 'object' ? this.taskData.make && this.taskData.make.label || 'brak danych' : this.taskData.make) || 'brak danych',
        model: (typeof this.taskData.model === 'object' ? this.taskData.model && this.taskData.model.label || 'brak danych' : this.taskData.model) || 'brak danych',
        registrationNumber: this.taskData.registrationNumber || 'brak danych',
        ic: this.taskData.ic || 'brak danych',
        victimFullname: this.getVictimFullname(),
        victimStreet: this.taskData.victims[0] && this.taskData.victims[0].person && this.taskData.victims[0].person.address.street || 'brak danych',
        victimPostcode: this.taskData.victims[0] && this.taskData.victims[0].person && this.taskData.victims[0].person.address.postcode || 'brak danych',
        victimCity: this.taskData.victims[0] && this.taskData.victims[0].person && this.taskData.victims[0].person.address.city || 'brak danych',
        // claimPersonFullname: this.task.assignee && this.task.assignee.fullname || 'brak danych',
        // claimPersonEmail: this.task.assignee && this.task.assignee.email || 'brak danych',
        participantFullname: this.getParticipantFullname(),
        participantStreet: this.taskData.accidentParticipants[0] && this.taskData.accidentParticipants[0].person && this.taskData.accidentParticipants[0].person.address.street || 'brak danych',
        participantPostcode: this.taskData.accidentParticipants[0] && this.taskData.accidentParticipants[0].person && this.taskData.accidentParticipants[0].person.address.postcode || 'brak danych',
        participantCity: this.taskData.accidentParticipants[0] && this.taskData.accidentParticipants[0].person && this.taskData.accidentParticipants[0].person.address.city || 'brak danych',
        participantRegistrationNumber: this.taskData.accidentParticipants[0] && this.taskData.accidentParticipants[0].registrationNumber || 'brak danych',
        participantMake: this.taskData.accidentParticipants[0] && (typeof this.taskData.accidentParticipants[0].make === 'object' ? this.taskData.accidentParticipants[0].make && this.taskData.accidentParticipants[0].make.label || 'brak danych' : this.taskData.accidentParticipants[0].make && this.taskData.accidentParticipants[0].make) || 'brak danych',
        participantModel: this.taskData.accidentParticipants[0] && (typeof this.taskData.accidentParticipants[0].model === 'object' ? this.taskData.accidentParticipants[0].model && this.taskData.accidentParticipants[0].model.label || 'brak danych' : this.taskData.accidentParticipants[0].model && this.taskData.accidentParticipants[0].model) || 'brak danych',
        bankFullname: this.taskData.bankAssignment || 'brak danych',
        bankStreet: this.subjectData && this.subjectData.street || 'brak danych',
        bankPostcode: this.subjectData && this.subjectData.postcode || 'brak danych',
        bankCity: this.subjectData && this.subjectData.city || 'brak danych',
        policeFullname: this.taskData.policeDepartment || 'brak danych',
        policeStreet: this.subjectData && this.subjectData.street || 'brak danych',
        policePostcode: this.subjectData && this.subjectData.postcode || 'brak danych',
        policeCity: this.subjectData && this.subjectData.city || 'brak danych',
        claimPersonFullname: this.claimPersonLabels && this.claimPersonLabels.name || 'brak danych',
        claimPersonEmail: this.claimPersonLabels && this.claimPersonLabels.email || 'brak danych',
        comments: this.taskData.comments || 'brak danych',
        // Wypłaty:
        // kosztySuma (Kwota wypłaty)
        costsSum: this.chosenPayment && this.chosenPayment.amount || 'brak danych',
        // potrącenia opis
        deductionsLabel: this.chosenPayment && this.chosenPayment.deductions && this.chosenPayment.deductions.map(el => el.description || 'brak danych').join(';') || 'brak danych',
        // potrącenia
        deductions: this.chosenPayment && this.chosenPayment.deductions && this.chosenPayment.deductions.map(el => `${el.value} zł` || 'brak danych').join(';') || 'brak danych',
        // Odszkodowanie kwota
        payout: this.chosenPayment && this.chosenPayment.amount || 'brak danych',
        // Odszkodowanie kwota słownie
        payoutByWords: this.getAmountByWords() || 'brak danych',
        // uzasadnienie Treść
        grounds: this.chosenPayment && this.chosenPayment.description || 'brak danych',
        // odbiorca (konto dealera , inne konto, poszkodowany)
        paymentRecipient: this.chosenPayment && this.chosenPayment.paymentMode && this.paymentModeMap[this.chosenPayment.paymentMode] || 'brak danych',
        recipientFullname: this.chosenPayment && this.chosenPayment.fullname || 'brak danych',
        recipientStreet: this.chosenPayment && this.chosenPayment.street || 'brak danych',
        recipientCity: this.chosenPayment && this.chosenPayment.city || 'brak danych',
        recipientPostcode: this.chosenPayment && this.chosenPayment.postCode || 'brak danych'
      }
    },
    getAmountByWords () {
      if (this.chosenPayment && this.chosenPayment.amount) {
        let suffixZL = this.getSuffix(this.words(this.chosenPayment.amount.split('.')[0]), ['złoty', 'złote', 'złotych'])
        let suffixGR = this.getSuffix(this.words(this.chosenPayment.amount.split('.')[1]), ['grosz', 'grosze', 'groszy'])
        return this.chosenPayment && this.chosenPayment.amount &&
            `${this.words(this.chosenPayment.amount.split('.')[0])} ${suffixZL} ${this.words(this.chosenPayment.amount.split('.')[1])} ${suffixGR}`
      } else {
        return false
      }
    }
  }
}
</script>
<style>
    .document-payment-select select {
        height: 2.7vh;
    }
</style>
