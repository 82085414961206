<template>
  <base-form-modal
    :show="show"
    :title="title"
    :ok-text="okText"
    :cancel-text="cancelText"
    @show="open"
    @close="$emit('close')"
    @beforeSubmit="bool => beforeSubmitTrigger = bool"
  >
    <form-modal
      ref="form"
      v-model="model"
      :is-before-submit="beforeSubmitTrigger"
      :task-id="taskId"
      :open="show"
      :assignee-uuid="assigneeUuid"
      @multiselectInput="handleChangeUser"
    />
  </base-form-modal>
</template>

<script>
import BaseFormModal from '../../../../share/modal/BaseFormModal'
import FormModalMixin from '../../../../share/modal/FormModalMixin'
import FormModal from './form/ChangeAssignee'
import api from '../../../../../api'

export default {
  components: {
    FormModal,
    BaseFormModal
  },
  mixins: [
    FormModalMixin
  ],
  props: {
    taskId: { required: true },
    assigneeUuid: { type: String, required: true, default: '' }
  },
  data () {
    return {
      title: this.$t('pages.taskDetails.changeAssignee.title'),
      okText:  this.$t('pages.taskDetails.changeAssignee.okButton'),
    }
  },
  methods: {
    handleChangeUser (event) {
      let services = ['extgen', 'extpko', 'extavi', 'demofokus', 'extuni', 'extgcz', 'extgsl']
      if (services.indexOf(this.service) > -1) {
        this.model = event
      } else {
        this.model.selectedUserUuid = event
      }
    },
    submitForm () {
      let services = ['extgen', 'extpko', 'extavi', 'demofokus', 'extuni', 'extgcz', 'extgsl']
      if (services.indexOf(this.service) > -1) {
        try {
          let dataToSend = {
            newBusinessHour: {
              id: this.model.businessHourId,
              startAt: this.model.startAt,
              endAt: this.model.endAt
            }
          }
          api.request(this.service, 'put', `/booking-dates/${this.model.bookingDateToChange}/edit`, dataToSend)
            .then((response) => {
              this.$events.emit('task:changedState')
            }).catch((error) => {
              this.errorNotify(error)
            })
          this.$events.emit('editBookingDateEvent')
        } catch (e) {
          console.log(e)
          this.errorNotify(e, 'error', this.$t('calendar.reserveModal.errorNodataTitle'), this.$t('calendar.reserveModal.errorNodataText'))
        }
      } else {
        api.request(this.service, 'put', `/tasks/${this.taskId}`, this.createDataToSend())
          .then((response) => {
            this.$notify({
              type: 'success',
              text: this.$t('pages.taskDetails.changeAssignee.assigneeChanged')
            })

            this.$emit('close')
            this.$events.emit('task:changedState')
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: this.$t('error.default'),
              text: this.$t('pages.taskDetails.changeAssignee.assigneeNotChanged')
            })
          })
      }
    },
    createDataToSend () {
      let dataToSend = {
        assignee: this.model.selectedUserUuid
      }
      if (this.$isWithClients(this.service) && this.$route.meta.client) {
        dataToSend['client'] = this.$route.meta.client
        if (this.$route.meta.taskType === 'appraisal') {
          dataToSend['appraisal'] = true
        } else {
          dataToSend['main'] = true
        }
      }
      return dataToSend
    }
  }
}
</script>
