<template>
  <div>
    <h2 class="tab-header">
      Wypłaty
    </h2>
    <grid
      :source="gridDataSource"
      :name="gridName"
      :columns="gridColumns"
      :actions="gridActions"
      :additional-params="getAdditionalParams"
      @show="actionShow"
    />
  </div>
</template>

<script>
import Grid from '../../share/grid/Grid'
import ErrorNotify from '../../../share/mixins/ErrorNotify'

export default {
  components: {
    Grid
  },
  mixins: [
    ErrorNotify
  ],
  data () {
    return {
      gridName: 'grid_payments',
      gridColumns: [
        { id: 0, data: 'id', title: 'L.P.', searchable: false, orderable: false },
        { id: 1, data: 'author', title: 'Autor', searchable: false, orderable: false },
        { id: 2, data: 'createdAt', title: 'Data dodania' },
        { id: 3, data: 'amount', title: 'Kwota wypłaty' },
        { id: 4, data: 'amountType', title: 'Netto / Brutto', searchable: false, orderable: false },
        { id: 5, data: 'paymentMode', title: 'Odbiorca', searchable: false, orderable: false },
        { id: 6, data: 'paymentAt', title: 'Data wypłaty' }
      ],
      service: this.$route.meta.acl.service
    }
  },
  computed: {
    gridActions () {
      return [
        { id: 0, event: 'show', title: 'Szczegóły wypłaty', icon: 'eye', params: [], acl: { service: this.service, action: this.$isWithClients(this.service) ? 'get_payment' : 'get_task_main_payment' } }
      ]
    },
    getAdditionalParams () {
      return this.$isWithClients(this.service) ? { taskId: this.$route.params.id } : null
    },
    gridDataSource () {
      return { service: this.service, url: this.$isWithClients(this.service) ? '/payments' : `/tasks/${this.$route.params.id}/main/payment` }
    }
  },
  methods: {
    actionShow (id, params) {
      this.$isWithClients(this.service)
        ? this.$router.push({ name: `${this.service}_${this.$route.meta.client}_${this.$route.meta.workflowName}_payment_task_details`, params: { id: this.$route.params.id, paymentTaskId: id } })
        : this.$router.push({ name: `${this.service}_${this.$route.meta.workflowName}_payment_task_details`, params: { id: this.$route.params.id, paymentTaskId: id } })
    }
  }
}
</script>

<style scoped>

</style>
