<template>
  <div>
    <template
      v-for="(fields, index) in schema.schema.groups"
    >
      <div
        v-if="schema.schema.groups[index].fields"
        :key="index"
      >
        <vue-form-generator
          :schema="{groups: [fields]}"
          :model="model"
          :options="collectionFormOptions"
          @model-updated="modelUpdated"
          @collection-updated="submitCollectionField"
        />
        <button
          v-if="schema.schema.groups.length > 1"
          type="button"
          class="button-delete"
          @click="remove(index)"
        />
      </div>

    </template>
    <div>
      <div
        type="button"
        class="button-add"
        @click="add"
      /><span class="button-add-label">{{$t('pages.taskDetails.taskForm.collectionAddButton')}}</span>
    </div>
  </div>
</template>

<script>
import { abstractField } from 'vue-form-generator'
import _ from 'lodash'

export default {
  mixins: [abstractField],
  computed: {
    collectionFormOptions () {
      let formOptions = _.cloneDeep(this.formOptions)
      return Object.assign(formOptions, { 'collection': true })
    }
  },
  methods: {
    add () {
      let groupsLength = this.schema.schema.groups.length
      let fields = _.cloneDeep(this.schema.baseCollectionFields)

      for (let f = 0; f < fields.length; f++) {
        let fieldModel = this.getPrepareFieldModel(fields[f], groupsLength)
        fields[f].collectionIndex = groupsLength
        Object.assign(fields[f], fieldModel)
      }

      this.schema.schema.groups.push({ fields: fields })
    },
    remove (index) {
      if (_.has(this.model, `[${this.schema.collection}][${index}]`) && this.model[this.schema.collection][index] !== undefined) {
        // check if current removal is already in our db
        let shouldItemBeDeleted = this.model[this.schema.collection][index].hasOwnProperty('id')
        this.submitCollectionField(this.model[this.schema.collection][index], { collectionName: this.schema.collection, collectionIndex: index }, null, shouldItemBeDeleted)
      }

      this.$set(this.schema.schema.groups, index, {})
      this.model[this.schema.collection][index] = Object.assign({})
    },
    getPrepareFieldModel (field, collectionIndex) {
      let fieldParams = {}
      let fieldModel = field.model

      fieldParams.model = fieldModel.replace(/\[i\]/g, '[' + collectionIndex + ']')

      return fieldParams
    },
    modelUpdated (newValue, modelName) {
      this.$emit('model-updated', newValue, modelName)
    },
    submitCollectionField (collectionNewVal, schema, target, shouldRemove = false) {
      if (shouldRemove) {
        this.modelUpdated(collectionNewVal, schema)
      }

      this.$emit('collection-updated', collectionNewVal, schema, target, shouldRemove)
    }
  }
}

</script>
