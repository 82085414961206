<script>
import Schema from '../../../share/mixins/Schema.js'

export default {
  mixins: [
    Schema
  ],
  props: {
    service: {
      type: String,
      required: true
    }
  }
}
</script>
