<template>
  <div>
    <h2 class=" tab-header">
      Podzlecenia
    </h2>
    <div
      v-if="preparedDataToList.length !== 0"
      class=""
    >
      <grid
        :service="service"
        :content="preparedDataToList"
        :name="gridName"
        :columns="gridColumns"
        :actions="gridActions"
        :searchable="false"
        :server-side="false"
        @show="actionShow"
      />
    </div>
    <div
      v-else
      class="content-empty"
    >
      Nie ma żadnych podzleceń
    </div>
  </div>
</template>
<script>
import Grid from '../../share/grid/Grid'
import WebStorage from '../../../share/WebStorage'

export default {
  components: {
    Grid
  },
  props: {
    service: { type: String, required: true },
    subtasks: { type: Array },
    task: { type: Object },
    workflowName: { type: String }
  },
  data () {
    return {
      gridName: 'grid_appraisal_tasks',
      gridColumns: [
        { id: 1, data: 'number', title: 'Numer podzlecenia', searchable: false },
        { id: 2, data: 'type', title: 'Typ zlecenia', searchable: false },
        { id: 3, data: 'status', title: 'Status', searchable: false },
        { id: 4, data: 'assignee', title: 'Opiekun', searchable: false },
        { id: 5, data: 'createdAt', title: 'Data utworzenia', searchable: false },
        { id: 6, data: 'confirmedAt', title: 'Potwierdzenie', searchable: false },
        {
          id: 7,
          data: 'appointmentWithVictimAt',
          title: 'Kontakt',
          searchable: false
        },
        { id: 8, data: 'inspectedAt', title: 'Oględziny', searchable: false },
        {
          id: 9,
          data: 'bid',
          title: 'Stawka',
          searchable: false,
          render (data, type, row, meta) {
            if (data !== null) {
              if (data.toString().includes('.')) {
                if (data.toString().split('.')[1].length === 2) {
                  return `${data} zł`
                } else if (data.toString().split('.')[1].length === 1) {
                  return data.toString() + '0 zł'
                }
              } else {
                return data.toString() + '.00 zł'
              }
            } else {
              return ''
            }
          }
        }
      ]
    }
  },
  computed: {
    client () {
      return this.$route.meta.client || this.$route.params.clientSymbol
    },
    gridActions () {
      return [
        {
          id: 0,
          event: 'show',
          title: 'Szczegóły',
          icon: 'eye',
          params: ['parentId', 'supportedClass'],
          acl: { service: this.service, action: this.$isWithClients(this.service) ? `get_task__appraisal__${this.client}` : 'get_appraisal_task' }
        }
      ]
    },
    preparedDataToList () {
      return this.subtasks.map(subtask => {
        return {
          id: subtask.id,
          supportedClass: subtask.supportedClass,
          number: subtask.number,
          type: subtask.additionalData.appraisalOrderType,
          status: subtask.state.label,
          assignee: subtask.assignee ? subtask.assignee.fullname ? subtask.assignee.fullname : subtask.assignee.label : '',
          createdAt: subtask.createdAt,
          parentId: this.task.id,
          confirmedAt: subtask.additionalData.confirmedAt,
          inspectedAt: subtask.additionalData.inspectedAt,
          appointmentWithVictimAt: subtask.additionalData.appointmentWithVictimAt,
          bid: subtask.additionalData.bid,
          appraisalWorkflowName: 'appraisal'
        }
      })
    }
  },
  created () {
    this.getInitialGridColumns()
  },
  methods: {
    getInitialGridColumns () {
      let actions = WebStorage.getSecurityActions()
      this.gridColumns = [
        { id: 1, data: 'number', title: 'Numer podzlecenia', searchable: false },
        { id: 2, data: 'type', title: 'Typ zlecenia', searchable: false },
        { id: 3, data: 'status', title: 'Status', searchable: false },
        { id: 4, data: 'assignee', title: 'Opiekun', searchable: false },
        { id: 5, data: 'createdAt', title: 'Data utworzenia', searchable: false },
        { id: 6, data: 'confirmedAt', title: 'Potwierdzenie', searchable: false },
        {
          id: 7,
          data: 'appointmentWithVictimAt',
          title: 'Kontakt',
          searchable: false
        },
        { id: 8, data: 'inspectedAt', title: 'Oględziny', searchable: false },
        {
          id: 9,
          data: 'bid',
          title: 'Stawka',
          searchable: false,
          render (data, type, row, meta) {
            if (data !== null) {
              if (data.toString().includes('.')) {
                if (data.toString().split('.')[1].length === 2) {
                  return `${data} zł`
                } else if (data.toString().split('.')[1].length === 1) {
                  return data.toString() + '0 zł'
                }
              } else {
                return data.toString() + '.00 zł'
              }
            } else {
              return ''
            }
          }
        }
      ].filter(column => {
        if (column.data === 'bid') {
          if (actions[this.service].includes('frontend_acl_get_bid_column')) {
            return column
          }
        } else {
          return column
        }
      })
    },
    actionShow (id, params) {
      if (params && params.parentId) {
        if (this.$isWithClients(this.service)) {
          return this.$router.push({
            name: `${this.service}_${this.client}_${this.workflowName}_appraisal_task_details`,
            params: { id: params.parentId, appraisalTaskId: id }
          })
        } else {
          return this.$router.push({
            name: `${this.service}_${this.workflowName}_appraisal_task_details`,
            params: { id: params.parentId, appraisalTaskId: id }
          })
        }
      }
    }
  }
}
</script>
