import fieldMultiselect from './fieldMultiselect.js'
import NewPersonModal from '../../../../bundles/share/admin/person/modal/NewPerson'

export default {
  template: `
      <div :id="'task-' + schema.model">
        <multiselect
          :id="'input-' + schema.model"
          v-model="multiselectValue"
          :label="viewOption"
          :options="optionsList"
          :track-by="trackBy"
          :multiple="multiple"
          :selectLabel="selectLabel"
          :disabled="disabled"
          :selectedLabel="selectedLabel"
          :placeholder="placeholder"
          :deselectLabel="deselectLabel"
          :selectGroupLabel="selectGroupLabel"
          :deselectGroupLabel="deselectGroupLabel"
          :loading="asyncLoading"
          @input="updateValue"
          @search-change="asyncFind"
          @close="onBlur(onBlurEvent)"
          >
          <template slot="noResult">
            <span
              :data-deselect="$t('pages.taskDetails.form.multiselect.fieldMultiselect.add')"
              class="multiselect__option multiselect__option--highlight multiselect__option--selected"
              @click="addPerson"
              >
                <span>{{$t('pages.taskDetails.form.multiselect.fieldMultiselect.noData')}}</span>
            </span>
          </template>
          <template slot="noOptions">
            <span
              :data-deselect="$t('pages.taskDetails.form.multiselect.fieldMultiselect.add')"
              class="multiselect__option multiselect__option--highlight multiselect__option--selected"
              @click="addPerson"
              >
                <span>{{$t('pages.taskDetails.form.multiselect.fieldMultiselect.noData')}}</span>
            </span>
          </template>
        </multiselect>

        <new-person-modal
          :show="modalVisible"
          @close="modalVisible = false"
          mode="new"
          :service="source.service"
          @savedModel="updateModel">
        </new-person-modal>
    </div>
  `,
  components: {
    NewPersonModal
  },
  mixins: [
    fieldMultiselect
  ],
  data () {
    return {
      modalVisible: false
    }
  },
  methods: {
    addPerson () {
      this.modalVisible = true
    },
    updateModel (savedModel) {
      let options = {
        id: savedModel.id,
        label: savedModel.forename + ' ' + savedModel.surname,
        entity: savedModel
      }

      this.updateValue(options)
      this.onBlur(this.onBlurEvent)
    }
  }
}
