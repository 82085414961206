<template>
  <div
    v-if="dataToShow"
    class="box box-default box-solid"
    style="margin-bottom: 0; z-index: 1000;"
  >
    <div
      v-if="isAppraisal"
      class="box-body box-task-header"
    >
      <template
        v-for="(data, headerIndex) in appraisalHeaderData"
      >
        <ul
          v-if="headerIndex === 0"
          :key="`header-${headerIndex}`"
          class="list-unstyled task-header--details row"
        >
          <template
            v-for="(text, index) in data"
          >
            <li
              v-if="data.length > 0"
              :key="index"
              class="col-xs-3"
            >
              <label class="align-right col-xs-6">{{ $t(text[1]) }}:</label>
              <span
                :id="text[2]"
                class=""
              > {{ text[0] }}</span>
            </li>
          </template>
        </ul>
      </template>
      <ul
        class="list-unstyled task-header--details row collapsable-panel"
      >
        <template
          v-for="(data, headerIndex) in appraisalHeaderData"
        >
          <li
            v-if="headerIndex !== 0"
            :key="headerIndex"
          >
            <ul
              :key="`header-${headerIndex}`"
              class="list-unstyled task-header--details row"
            >
              <template
                v-for="(text, index) in data"
              >
                <li
                  v-if="data.length > 0"
                  :key="index"
                  class="col-xs-3"
                >
                  <label class="align-right col-xs-6">{{ text[1] }}:</label>
                  <span
                    :id="text[2]"
                    class=""
                  > {{ text[0] }}</span>
                </li>
              </template>

            </ul>
          </li>
        </template>
      </ul>

    </div>
    <div
      v-else
      class="box-body box-task-header"
    >
      <template
        v-for="(data, dataToShowIndex) in dataToShow"
      >
        <ul
          v-if="dataToShowIndex === 0"
          :key="dataToShowIndex"
          class="list-unstyled task-header--details row"
        >
          <template
            v-for="(text, index) in data"
          >
            <li
              v-if="data.length > 0"
              :key="index"
              class="col-xs-3"
            >
              <label class="align-right col-xs-6">{{ $t(text[1]) }}:</label><span
              :id="text[2]"
              class=""
            > {{ text[0] }}</span>
            </li>
          </template>
        </ul>
        <ul
          v-if="dataToShowIndex !== 0"
          class="list-unstyled task-header--details row collapsable-panel"
          :key="dataToShowIndex"
        >
          <li class="col-xs-12">
          <ul
            v-if="dataToShowIndex !== 0"
            :key="dataToShowIndex"
            class="list-unstyled task-header--details row"
          >
            <template
              v-for="(text, index) in data"
            >
              <li
                v-if="data.length > 0"
                :key="index"
                class="col-xs-3"
              >
                <label class="align-right col-xs-6">{{ text[1] }}:</label><span
                :id="text[2]"
                class=""
              > {{ text[0] }}</span>
              </li>
            </template>
          </ul>
          <RelatedTasks
            v-if="relatedTasks && dataToShowIndex === (dataToShow.length - 1)"
            :related-tasks="relatedTasks"
            :workflow-name="workflowName"
            :service="service"
            :task="task"
          ></RelatedTasks>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
  import api from '../../../../../api'
  import RelatedTasks from '../../../share/TaskHeader/RelatedTasks'

  export default {
    name: 'TaskHeader',
    components: {RelatedTasks},
    props: {
      task: {},
      dataToShow: {type: Array},
      relatedTasks: {type: Array, default: () => []},
      workflowName: {type: String},
      service: {type: String, required: true},
      client: {type: String}
    },
    data () {
      return {
        loading: false,
        headerSchema: [],
        dataLabels: {
          state: 'Status',
          author: 'Autor',
          assignee: 'Opiekun',
          appraisalOrderType: 'Zlecenie',
          initialDate: 'Zlecone',
          expectedAccomplishmentDate: 'Oczekiwane',
          accomplishAt: 'Wykonano',
          registrationNumber: 'Numer rejestracyjny',
          'victims[i].person': 'Poszkodowany',
          make: 'Marka',
          model: 'Model',
        },
        taskHeader: null,
        taskHeaderHasShadow: false,
        taskHeaderCollapsed: false,
      }
    },
    computed: {
      isAppraisal () {
        if (this.task) {
          return this.task.supportedClass.includes('Appraisal')
        } else {
          return false
        }
      },
      appraisalHeaderData () {
        return this.headerSchema.length !== 0 ? this.headerSchema : this.dataToShow
      }
    },
    mounted () {
      this.taskHeader = document.querySelector('.floating-task-header')
      this.$emit('task-header-shadow', {hasShadow: false, height: this.taskHeader.getBoundingClientRect().height})

      this.scrollListener('add')
      if (this.isAppraisal && this.$store.state.base.securityActions[this.service].includes('get_task_header')) {
        this.getTaskHeaderSchema()
      }
    },
    watch: {
      taskHeaderHasShadow (newVal, prevVal) {
        if (newVal) {
          this.$emit('task-header-shadow', {hasShadow: true, height: this.taskHeader.getBoundingClientRect().height})
          return
        }
        this.$emit('task-header-shadow', {hasShadow: false, height: this.taskHeader.getBoundingClientRect().height})
      },
      taskHeaderCollapsed (newVal, prevVal) {
        if (newVal && this.taskHeaderHasShadow) {
          this.$emit('task-header-collapsed', {collapsed: true, height: this.taskHeader.getBoundingClientRect().height})
          return
        }
        this.$emit('task-header-collapsed', {collapsed: false, height: this.taskHeader.getBoundingClientRect().height})
      }
    },
    beforeDestroy () {
      this.scrollListener('remove')
    },
    methods: {
      scrollListener (type) {
        let dashboardWrapper = document.getElementsByClassName('wrapper')
        if (type === 'add') {
          dashboardWrapper[0].addEventListener('scroll', this.addShadow)
        } else {
          dashboardWrapper[0].removeEventListener('scroll', this.addShadow)
        }
      },
      addShadow () {
        let dashboardWrapper = document.getElementsByClassName('wrapper')
        let taskForm = document.getElementsByClassName('task-form')
        this.taskHeader = document.querySelector('.floating-task-header')
        if (dashboardWrapper[0].scrollTop > 50) {
          this.taskHeaderHasShadow = true
          this.$el.classList.add('task-header-shadow')
        } else {
          this.taskHeaderHasShadow = false
          this.$el.classList.remove('task-header-shadow')
        }
        if (taskForm[0].getBoundingClientRect().y  < 0 - this.taskHeader.getBoundingClientRect().height) {
          this.taskHeaderCollapsed = true
          this.$el.classList.add('task-header-collapsed')
        } else {
          this.taskHeaderCollapsed = false
          this.$el.classList.remove('task-header-collapsed')
        }      },
      getTaskHeaderSchema () {
        this.loading = true
        api.request(this.service, 'get', `/headers/${this.task.id}`)
          .then((response) => {
            if (response.data.length === 0) {
              return
            }
            let schema = []
            schema = response.data[0].fields.map((field, index) => {
              return [
                field.value,
                this.getFieldLabel(field),
                `${this.task.id}-header-state`
              ]
            })

            let rowLength = 4
            let rowsNumber = Math.ceil(schema.length / rowLength)
            let headerSchema = []

            for (let i = 0; i < rowsNumber; i++) {
              let currentRow = []
              for (let j = 0; j < rowLength; j++) {
                if (schema[i * rowLength + j]) {
                  currentRow.push(schema[i * rowLength + j])
                }
              }
              headerSchema.push(currentRow)
            }
            this.headerSchema = headerSchema
            this.loading = false
          })
          .catch((e) => {
            console.error('task.header.not_loaded', e)
          })
      },
      getFieldLabel (field) {
        let suffixes = {
          email: '- email',
          phone: '- telefon'
        }
        if (field.name.includes(':')) {
          return this.dataLabels[field.name.split(':')[0]] + ' ' + suffixes[field.name.split(':')[1]]
        }
        return this.dataLabels[field.name] || field.name
      }
    }
  }
</script>

<style scoped>
  .task-header-collapsed .collapsable-panel {
    transition: height ease 0.25s;
    position: absolute;
    width: 100%;
    background: white;
    box-shadow: 0px 19px 10px -8px rgba(138, 138, 138, 1);
  }

  .align-right {
    text-align: right;
  }

  .task-header-shadow {
    box-shadow: 0px 9px 15px -8px rgba(138, 138, 138, 1);
    transition: all ease 0.1s;
  }

  .task-header-shadow:hover .list-unstyled.collapsable-panel {
    transition: opacity ease 0.3s, height ease 0.2s;
    opacity: 1;
    visibility: visible;
    height: auto;
  }

  .task-header-collapsed .list-unstyled.collapsable-panel {
    transition: opacity ease 0.3s, height ease 0.2s;
    opacity: 0;
    visibility: collapse;
    height: 0;
  }

  .related-task-link {
    cursor: pointer;
  }
</style>
