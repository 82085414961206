<template>
  <base-form-modal
    id="generatorModal"
    :show="show"
    :title="title"
    :ok-text="okText"
    :cancel-text="cancelText"
    @show="open"
    @close="$emit('close')"
    @beforeSubmit="bool => beforeSubmitTrigger = bool"
  >
    <form-modal
      ref="form"
      v-model="model"
      :is-before-submit="beforeSubmitTrigger"
      :task-id="taskId"
      :task-state="taskState"
      :task="task"
      :available-documents="availableDocuments"
      :task-data="taskData"
    />
  </base-form-modal>
</template>

<script>
import BaseFormModal from '../../../../share/modal/BaseFormModal'
import FormModalMixin from '../../../../share/modal/FormModalMixin'
import FormModal from '../form/GenerateDocument'

export default {
  components: {
    FormModal,
    BaseFormModal
  },
  mixins: [
    FormModalMixin
  ],
  props: {
    taskId: { required: true },
    taskState: { required: true, default: '' },
    availableDocuments: { required: true },
    task: { required: true },
    taskData: { required: true }
  },
  data () {
    return {
      title: 'Generuj pismo',
      okText: 'Generuj'
    }
  }
}
</script>
<style>
  #generatorModal > div > div > div.modal-footer > div > button.btn.btn-primary {
    display: none;
  }
</style>
