<template>
  <form>
    <select-type
      v-if="transitionsArrayLength > 1"
      ref="stateSelect"
      v-model="value['selectedTransitionName']"
      v-validate="{ rules: {required: true}}"
      name="status"
      :label="$t('modals.changeState.action')"
      :required="true"
      :disabled="!statesLoaded"
      :options="transitions"
      :empty-first="true"
      :error-message="vErrors.first('status')"
      :data-vv-as="$t('modals.changeState.action')"
      @input="stateChange"
    />
    <div v-if="transitions.length === 1">
      <p class="fake-label">
        {{$t('modals.changeState.action')}}:
      </p> <p class="fake-select-replacement">
        {{ transitions[0].label }}
      </p>
    </div>
    <div
      v-if="value['selectedTransitionName'] && showUserSelect"
      :id="'change-state-assignee-multiselect'"
    >
      <label for="responsible">{{$t('modals.changeState.assigneeMultiselect.dataReuqirement')}}</label>
      <vue-multiselect
        v-if="possibleUsersArrayLength > 1"
        :id="'input-change-state-assignee-multiselect'"
        v-validate="'required'"
        class="assignee-multiselect"
        :name="'responsible'"
        :value="multiselectValue"
        :label="'label'"
        :select-label="$t('modals.changeState.assigneeMultiselect.chose')"
        :selected-label="$t('modals.changeState.assigneeMultiselect.chosen')"
        :deselect-label="$t('modals.changeState.assigneeMultiselect.deselect')"
        :options="possibleUsers"
        :data-vv-as="$t('modals.changeState.assigneeMultiselect.dataReuqirement')"
        :placeholder="$t('modals.changeState.assigneeMultiselect.placeholder')"
        :class="{'input': true, 'is-danger': vErrors.has('responsible') }"
        @input="handleMultiselectInput"
      >
        <template slot="noResult">
          <p>{{$t('modals.changeState.assigneeMultiselect.noData')}}</p>
        </template>
      </vue-multiselect>
      <span>{{ vErrors.first('responsible') }}</span>
    </div>
    <div v-if="possibleUsers.length === 1">
      <p class="fake-label">
        {{$t('modals.changeState.assigneeMultiselect.assignee')}}:
      </p><p class="fake-select-replacement">
        {{ possibleUsers[0].label }}
      </p>
    </div>
  </form>
</template>

<script>
import Loader from '../../../../../share/Loader'
import FormModalValidation from '../../../../../share/form/FormModalValidation'
import SelectType from '../../../../../share/form/type/SelectType'
import api from '../../../../../../api'
import VueMultiselect from 'vue-multiselect'

export default {
  components: {
    VueMultiselect,
    SelectType
  },
  mixins: [
    Loader,
    FormModalValidation
  ],
  props: {
    value: {},
    taskId: { required: true },
    workflowName: { type: String, required: true }
  },
  data () {
    return {
      multiselectValue: null,
      // taskState comes-in from parents injection
      taskState: '',
      transitions: [],
      statesLoaded: false,
      showUserSelect: true,
      possibleUsers: [],
      service: this.$route.meta.acl.service,
      cache: {
        possibleUsers: {}
      }
    }
  },
  computed: {
    possibleUsersArrayLength () {
      return this.possibleUsers.length
    },
    transitionsArrayLength () {
      return this.transitions.length
    }
  },
  watch: {
    taskState (newVal) {
      this.loadStates()
    }
  },
  methods: {
    handleMultiselectInput (event) {
      if (event && event.value) {
        this.multiselectValue = event
        this.$emit('multiselectInput', event.value)
      } else {
        this.multiselectValue = ''
        this.$emit('multiselectInput', '')
      }
    },
    getTransitions () {
      return this.$isWithClients(this.service)
        ? api.request(this.service, 'get', `/transitions?enabled=true&taskId=${this.taskId}`)
        : api.request(this.service, 'get', `/tasks/${this.workflowName}/${this.taskId}/transitions?enabled=true`)
    },
    loadStates () {
      this.getTransitions()
        .then((response) => {
          let buttonLabel = ''
          const transitions = response.data.transitions
          const statesLabels = response.data.statesLabels

          for (let transition of transitions) {
            this.transitions.push({ label: statesLabels[transition.tos[0]], value: transition.name })
          }

          if (transitions.length === 1) {
            buttonLabel = response.data.transitionsLabels[transitions[0].name]
            this.stateChange(response.data.transitions[0].name)
          }

          this.setButtonLabel(buttonLabel)
          this.statesLoaded = true
        })
        .catch(() => {
          // TODO: distinguish between error and last possible transition
          // this.$notify({
          //   type: 'error',
          //   title: 'Wystąpił błąd',
          //   text: 'Nie można załadować stanów'
          // })
        })
    },
    setButtonLabel (label) {
      let buttonLabel = (label !== '') ? label : 'modals.changeState.moveForward'
      this.$events.$emit('dashboard:submenu:stateLabel', buttonLabel)
    },
    userChange (event) {
      let currentTransition = this.value['selectedTransitionName']
      this.$emit('input', {
        selectedTransitionName: currentTransition,
        selectedUserUuid: event
      })
    },
    stateChange (event) {
      this.value['selectedUserUuid'] = ''
      if (this.transitions.length === 1) {
        this.value['selectedTransitionName'] = ''
        this.value['selectedTransitionName'] = event
      }
      this.loadRolesWithUsers()
    },
    getLoadRolesWithUsersMethod () {
      let clientId
      if (this.$isWithClients(this.service)) {
        clientId = this.$store.state.base.user.serviceClients[this.service].find(client => client.id === this.$route.meta.client).id
      }

      return this.$isWithClients(this.service)
        ? api.request(this.service, 'get', `/workflows/${this.workflowName}/transitions/${this.value['selectedTransitionName']}/${this.taskId}/tos-assignee-list?clientId=${clientId}`)
        : api.request(this.service, 'get', `/workflows/${this.workflowName}/transitions/${this.value['selectedTransitionName']}/${this.taskId}/tos-assignee-list`)
    },
    loadRolesWithUsers () {
      if (this.value['selectedTransitionName'].length !== 0) {
        this.$events.emit('changeState:assignees:notLoaded')
        this.getLoadRolesWithUsersMethod()
          .then((response) => {
            this.toggleLoading()
            const result = response.data
            if (result.hidden) {
              this.showUserSelect = false
            } else {
              this.showUserSelect = true
              const usersUuids = Object.keys(result)
              this.possibleUsers = []

              for (let c = 0; c < usersUuids.length; c++) {
                let uuid = usersUuids[c]
                this.possibleUsers.push({
                  label: result[uuid], // userData.forename + ' ' + user.userData.surname + ' [' + userRoles.join(', ') + ']',
                  value: uuid
                })
              }
            }
            if (this.transitions.length === 1 && this.possibleUsers.length < 2) {
              this.value['selectedUserUuid'] = this.possibleUsers.length !== 0 ? this.possibleUsers[0].value : null
              this.$events.$emit(`${this.service}:main:simpleTransition`)
            } else if (this.transitions.length !== 1 && this.possibleUsers.length < 2) {
              this.value['selectedUserUuid'] = this.possibleUsers.length === 1 ? this.possibleUsers[0].value : null
            }
            this.$events.emit('changeState:assignees:loaded')
          })
          .catch(() => {
            this.toggleLoading()
            this.$notify({
              type: 'error',
              title: this.$t('modals.changeState.notification.loadUSersError.title'),
              text: this.$t('modals.changeState.notification.loadUSersError.text')
            })
          })
      } else if (this.value['selectedTransitionName'].length === 0) {
        this.possibleUsers = []
      }
    }
  }
}
</script>
<style scoped>
    .fake-label {
        text-align: left;
        color: #6ac13b;
        font-weight: bold;
    }
    .fake-select-replacement {
        text-align: left;
        font-weight: bold;
        margin-left: 20px;
    }
</style>
